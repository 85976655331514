import { useEffect, useRef } from 'react'
import {
  cityName,
  textReplacement,
  getValueTrackParams,
  adRefCityText,
} from '../helpers/adRefTracking'

export const useTextReplacement = (
  textToReplaceWith,
  replacementContainerClassName = 'personalizedText'
) => {
  const { city, locphysical } = getValueTrackParams() || {}
  const personalizedTextRef = useRef(null)

  useEffect(() => {
    try {
      const main = async () => {
        const mappedCityValue = await adRefCityText(city, { locphysical, city })
        textReplacement(
          personalizedTextRef,
          replacementContainerClassName,
          textToReplaceWith || mappedCityValue || cityName(city)
        )
      }
      main()
    } catch (e) {
      console.log('text replacement error', e)
    }
  }, [])
  return personalizedTextRef
}
